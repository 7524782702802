import { DonationProps, SearchSelectOption } from '@classy/campaign-page-blocks'
import { whammyFetch } from 'classy-api-proxies/whammy'
import { CampaignProgramDesignation } from 'services/designations'
import { logger } from 'utils/logger'

/**
 * Maps a CampaignProgramDesignation entity to a SearchSelectOption entity.
 */
export const toSearchSelectOption = (
  designation: CampaignProgramDesignation,
): SearchSelectOption => ({
  id: designation.id,
  label: designation.name,
})

/**
 * Maps a list of CampaignProgramDesignation entities to a list of SearchSelectOption entities,
 * including grouping them by their designation group.
 */
export const toSearchSelectOptions = (
  designations: CampaignProgramDesignation[],
): SearchSelectOption[] => {
  const options: Record<SearchSelectOption['id'], SearchSelectOption> = {}

  designations.forEach((designation) => {
    const groupId = designation.designationGroupId
    if (groupId in options) {
      const group = options[groupId]
      if (group.subOptions) {
        group.subOptions.push(toSearchSelectOption(designation))
      }
    } else {
      options[groupId] = {
        id: groupId,
        label: designation.designationGroupName,
        subOptions: [toSearchSelectOption(designation)],
      }
    }
  })

  return Object.values(options)
}

/**
 * Generates the fetch request for fetching program designations and transforming them into a
 * SearchSelectOption list, appropriate for being passed to the Donation block's SearchSelect
 * component.
 *
 * If there is any error, the initial program designations are returned.
 */
export const useFetchProgramDesignations = (
  campaignId: DonationProps['campaignId'],
  initialProgramDesignations: DonationProps['initialProgramDesignations'] = [],
) => {
  const fetchProgramDesignations = async (searchString = ''): Promise<SearchSelectOption[]> => {
    if (!campaignId) {
      logger('error', 'Donation controller expects a campaign Id to fetch program designations')
      return initialProgramDesignations
    }

    try {
      const response = await whammyFetch('/search/campaign-designations', {
        method: 'POST',
        body: JSON.stringify({ campaignId, searchString }),
      })
      const json = await response.json()
      const designations = toSearchSelectOptions(json)
      return designations
    } catch (e) {
      logger(
        'error',
        new Error('Donation controller unable to fetch program designations', { cause: e }),
        {
          context: { campaignId, searchString },
        },
      )
      return initialProgramDesignations
    }
  }

  return {
    fetchProgramDesignations,
  }
}
