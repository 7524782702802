import { BLOCK_TYPES, PageHeaderProps } from '@classy/campaign-page-blocks'
import { OrganizationDto } from 'models/organization'
import { DenormalizedPageData } from 'models/pages'

/**
 * Gets the PageHeader block out of the SharedBlocks object.
 */
export const getPageHeader = (sharedBlocks: DenormalizedPageData['sharedBlocks']) => {
  const block = Object.values(sharedBlocks).find((block) => block.type === BLOCK_TYPES.HEADER)

  if (!block) return

  // Narrow type: "PageHeaderProps | FooterProps" -> "PageHeaderProps"
  block.props = block.props as PageHeaderProps

  return block
}

/**
 * PageHeader page block props overrides.
 *
 * Attaches the organization's name.
 * If the cart is enabled, it will make the header sticky. Is applicable only to
 * donation page.
 */
export const addPageHeaderBlockProps = (
  blockProps: PageHeaderProps,
  organization: OrganizationDto,
  isCartEnabled: boolean,
) => {
  blockProps.orgName = organization?.name
  blockProps.isSticky = isCartEnabled
}
