import React from 'react'
import { useTranslation } from 'next-i18next'
import { ShoppingCart } from '@classy/campaign-page-blocks'
import * as Styled from './CartButton.styled'

export interface CartButton {
  cartCount: number
  isLoading: boolean
  // innerRef provided by floating-ui
  innerRef?: React.Ref<HTMLElement>
}

export const CartButton = ({ cartCount, isLoading, ...rest }: CartButton) => {
  const { t } = useTranslation('cart')

  return (
    <Styled.CartButton {...rest}>
      <ShoppingCart />
      {t('CartButton.cartButton', 'Cart')} {isLoading ? '' : cartCount}
    </Styled.CartButton>
  )
}
