/**
 * Server-only fetch() wrapper for calling any Currency endpoint that requires authentication.
 *
 * Automatically:
 * - Uses fetch() with an api key
 * - Returns the results of response
 *
 * Example:
 * const data = await currencyFetch<SearchResults>('/search/pet?cat=1', {
 *   method: 'POST',
 *   body: JSON.stringify({ color: 'tuxedo', solitary: false })
 * })
 */
export async function currencyFetch(path: string, fetchOptions?: RequestInit): Promise<Response> {
  const versionedPath = `${process.env.KONG_BASE_URI}/currency/1.0${path}`

  const fetchOptionsWithCommon = {
    ...fetchOptions,
    headers: {
      'X-API-KEY': `${process.env.CURRENCY_API_KEY}`,
      ...fetchOptions?.headers,
    },
  }

  return fetch(versionedPath, fetchOptionsWithCommon)
}
