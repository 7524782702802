import React from 'react'
import { EVENT } from 'models/event'
import {
  Donation,
  DonationProps,
  useCustomEventListener,
  DonationBlockOnInitialization,
  MAX_DONATION_AMOUNT,
  MIN_DONATION_AMOUNT,
} from '@classy/campaign-page-blocks'
import { intelligentAskAmountOverrideProps } from './donation'
import { useRouter } from 'next/router'
import { trackDonationFormInitEvent } from 'services/analytics/events/donationFormInitialization'
import { IntelligentAskAmountEventPayload } from 'services/intelligentAskAmount/intelligent-ask-amount'
import { CurrencyEventPayload } from 'features/Passport/GlobalCurrencyControl'
import { convertAmount, convertAmounts } from 'features/Passport/convertAmounts'
import { useFetchProgramDesignations } from './useFetchProgramDesignations'

interface DonationControllerProps extends DonationProps {
  sectionId?: string
}

export const DonationController = ({
  donationAmounts,
  recurringAmounts,
  currencyCode = 'USD',
  minDonationAmount = MIN_DONATION_AMOUNT,
  intelligentAskAmount,
  sectionId,
  campaignId,
  initialProgramDesignations,
  ...rest
}: DonationControllerProps) => {
  const router = useRouter()
  const { query } = router
  const urlAmountUsed = query.amount as string

  const intelligentAskAmountData: IntelligentAskAmountEventPayload = useCustomEventListener(
    EVENT.IAA_FETCH_AMOUNTS,
    {
      isLoading: true,
      suggested_donation_amount: null,
      suggested_recurring_amount: null,
      grid: {
        suggested_donation_amount: null,
        suggested_recurring_amount: null,
      },
      is_default_recurring: null,
      show_recurring_upsell: null,
    },
  )

  const currencyDetails: CurrencyEventPayload = useCustomEventListener(
    EVENT.PASSPORT_GET_CURRENCY_CONVERSION,
    {
      oldCurrencyCode: currencyCode,
      newCurrencyCode: currencyCode,
      currencyConversionRate: 1,
      isLoading: false,
    },
  )

  const onInitialization = ({ ...initBlockstate }: DonationBlockOnInitialization) => {
    trackDonationFormInitEvent({ initBlockstate, urlAmountUsed, sectionId })
  }

  const intelligentAskEnabled =
    intelligentAskAmount?.onetimeEnabled || intelligentAskAmount?.recurringEnabled

  const iaaOverrideProps = intelligentAskAmountOverrideProps(
    rest, // DonationBlock props
    intelligentAskAmountData, // iaa event/api data
    query, // query params
    donationAmounts,
    recurringAmounts,
    intelligentAskAmount, // settings data - onetimeEnabled, recurringEnabled
  )

  const convertedAmounts = React.useMemo(() => {
    const donationAmountsArray = intelligentAskEnabled
      ? iaaOverrideProps.donationAmounts
      : donationAmounts
    const recurringAmountsArray = intelligentAskEnabled
      ? iaaOverrideProps.recurringAmounts
      : recurringAmounts
    const suggestedDonationAmount = Number(iaaOverrideProps?.iaaData?.suggestedDonationAmount)
    const suggestedRecurringAmount = Number(iaaOverrideProps?.iaaData?.suggestedRecurringAmount)

    return {
      donation: convertAmounts({
        conversionRate: currencyDetails.currencyConversionRate,
        amounts: donationAmountsArray || [],
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      recurring: convertAmounts({
        conversionRate: currencyDetails.currencyConversionRate,
        amounts: recurringAmountsArray || [],
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      min: convertAmount({
        conversionRate: currencyDetails.currencyConversionRate,
        amount: minDonationAmount || MIN_DONATION_AMOUNT,
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      max: convertAmount({
        conversionRate: currencyDetails.currencyConversionRate,
        amount: MAX_DONATION_AMOUNT,
        campaignCurrencyCode: currencyCode,
        selectedCurrencyCode: currencyDetails.newCurrencyCode,
      }),
      iaaDonationAmount: suggestedDonationAmount
        ? String(
            convertAmount({
              conversionRate: currencyDetails.currencyConversionRate,
              amount: suggestedDonationAmount,
              campaignCurrencyCode: currencyCode,
              selectedCurrencyCode: currencyDetails.newCurrencyCode,
            }),
          )
        : '',
      iaaRecurringAmount: suggestedRecurringAmount
        ? String(
            convertAmount({
              conversionRate: currencyDetails.currencyConversionRate,
              amount: suggestedRecurringAmount,
              campaignCurrencyCode: currencyCode,
              selectedCurrencyCode: currencyDetails.newCurrencyCode,
            }),
          )
        : '',
    }
  }, [
    currencyDetails,
    donationAmounts,
    recurringAmounts,
    minDonationAmount,
    iaaOverrideProps.iaaData,
    iaaOverrideProps.donationAmounts,
    iaaOverrideProps.recurringAmounts,
    intelligentAskEnabled,
    currencyCode,
  ])

  /**
   * Callback for when the user enters a search term in the program designations SearchSelect.
   * This will get passed down as a prop to the Donation block.
   */
  const { fetchProgramDesignations } = useFetchProgramDesignations(
    campaignId,
    initialProgramDesignations,
  )

  const donationProps: DonationProps = {
    onInitialization: onInitialization,
    campaignId,
    currencyCode: currencyDetails.newCurrencyCode,
    recurringAmounts: convertedAmounts.recurring,
    donationAmounts: convertedAmounts.donation,
    minDonationAmount: convertedAmounts.min,
    maxDonationAmount: convertedAmounts.max,
    intelligentAskAmount: intelligentAskAmount,
    initialProgramDesignations: initialProgramDesignations,
    fetchProgramDesignations: fetchProgramDesignations,
  }

  if (!intelligentAskEnabled) {
    return <Donation isLoading={currencyDetails.isLoading} {...donationProps} {...rest} />
  }

  return (
    <Donation
      isLoading={intelligentAskAmountData.isLoading || currencyDetails.isLoading}
      {...iaaOverrideProps}
      {...donationProps}
      iaaData={{
        suggestedDonationAmount: convertedAmounts.iaaDonationAmount,
        suggestedRecurringAmount: convertedAmounts.iaaRecurringAmount,
      }}
      campaignId={campaignId}
    />
  )
}
