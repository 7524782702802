interface WhammyApiError extends Error {
  statusCode: number
}

interface WhammyApiErrorParams {
  message: string
  statusCode: number
}

/**
 * Returns a custom Error object for passing along a response status message and HTTP code.
 *
 * This Error object is primarily for whammyFetch() calls, but can be used with any classy-api-proxy
 * (apiv2, orders, etc.) as well.
 *
 * Uses:
 * - When calling a classy api (APIv2, Cart API, etc.) via the whammy api (/api/*), if there are
 *   any issues with the request or response, a WhammyApiError can be used to pass along a status
 *   message and HTTP code.
 * - If a classy api call has been promoted to a service function (/src/services/*), that function
 *   can use WhammyApiError to provide a consistent error path.
 *
 * Note, WhammyApiError is expected to be at the bottom of the error stack as it would be the first
 * error thrown. As such, we currently don't provide the option to add a `cause` to the error object
 * as an ErrorOption but this can be added at any time if the need arises.
 */
export const WhammyApiError = (
  { message, statusCode }: WhammyApiErrorParams,
  options?: ErrorOptions,
): WhammyApiError => {
  const error = new Error(message, options) as WhammyApiError
  error.name = 'WhammyApiError'
  error.statusCode = statusCode
  return error
}

export const isWhammyApiError = (error: unknown): error is WhammyApiError => {
  return error instanceof Error && error.name === 'WhammyApiError'
}
