import { PurchaseCookieDto } from 'models/purchase'
import { PageConfig } from 'models/pages'
import { getAnalyticsInstance } from '../analytics'

/*
 * E-Commerce "Purchase" Event is handled in distinct ways for each analytics tool.
 * Copied from classy-checkout/src/utils/analytics/functions/trackPurchaseEvents.ts.
 */
export const trackPurchaseEvent = (pageConfig: PageConfig, purchaseCookie: PurchaseCookieDto) => {
  const analytics = getAnalyticsInstance()

  const commonPurchasePayload = {
    event: 'purchase',
    transaction_id: purchaseCookie.id,
    currency: purchaseCookie.raw_currency_code,
    value: purchaseCookie.raw_total_gross_amount,
    tax: 0,
    shipping: 0,
    affiliation: 'Classy',
    coupon: undefined, // TODO: Update when checkout supports promo codes
    status: 'success',
    created_at: purchaseCookie.created_at,
    fees_covered: purchaseCookie.is_donor_covered_fee,
    has_dedication: purchaseCookie.has_dedication,
    refunded_at: undefined,
    billing_zip_code: purchaseCookie.billing_postal_code,
    is_gift_aid: false, // Gift Aid not currently supported
    tax_entity_id: 123,
    member_id: purchaseCookie.member_id,
    payment_source: purchaseCookie.payment_source,
    // * Currency related values
    displayed_currency_code: purchaseCookie.raw_currency_code,
    displayed_overhead_amount: purchaseCookie.raw_overhead_net_amount, // should always be 0
    displayed_donation_amount: purchaseCookie.raw_donation_gross_amount,
    displayed_total_gross_amount: Number(purchaseCookie.raw_total_gross_amount),
    normalized_currency_code: purchaseCookie.currency_code,
    normalized_overhead_amount: purchaseCookie.overhead_net_amount,
    normalized_donation_amount: purchaseCookie.donation_gross_amount,
    normalized_total_gross_amount: purchaseCookie.total_gross_amount,
    normalized_fees_covered: purchaseCookie.normalized_fees_covered,
    // * Array of order items or a single transaction item
    items: purchaseCookie.items.map((purchaseItem) => ({
      item_id: `donation_${purchaseItem.designationId}`,
      item_category: 'donation',
      quantity: purchaseItem.quantity,
      frequency: purchaseItem.frequency,
      price: purchaseItem.unitPrice,
      fundraising_page_id: undefined,
      fundraising_team_id: undefined,
      designation_id: purchaseItem.designationId,
      designation_name: purchaseItem.designationName,
      designation_external_reference_id: purchaseItem.designationExternalReferenceId,
    })),
    company_match: purchaseCookie.company_match,
    campaign: {
      id: pageConfig.campaignId,
      name: pageConfig.campaignName,
      features: pageConfig.isCartEnabled ? ['cart'] : [],
      type: 'studio',
      is_embedded: false,
      currency_code: pageConfig.campaignRawCurrencyCode,
      default_designation_id: pageConfig.defaultDesignationId,
      goal: pageConfig.campaignRawGoal,
      created_at: pageConfig.campaignCreatedAt,
    },
    organization: {
      id: pageConfig.orgId,
      name: pageConfig.orgName,
    },
    supporter: {
      id: purchaseCookie.supporter_id,
      first_name: purchaseCookie.billing_first_name,
      last_name: purchaseCookie.billing_last_name,
      postal_code: purchaseCookie.billing_postal_code,
      email_address: purchaseCookie.member_email_address,
      company_name: purchaseCookie.company_name,
      is_anonymous: purchaseCookie.is_anonymous,
    },
  }

  // Tealium, GA4, and GTM purchase
  analytics.track('purchase', commonPurchasePayload, {
    plugins: {
      all: false,
      'google-analytics': true,
      'google-tag-manager': true,
      tealium: true,
    },
  })

  // GTM ga_purchase event
  analytics.track(
    'ga_purchase',
    {
      ecommerce: {
        purchase: {
          actionField: {
            id: purchaseCookie.id,
            revenue: purchaseCookie.raw_total_gross_amount,
          },
          products: purchaseCookie.items.map((purchaseItem) => ({
            name: purchaseItem.name,
            id: purchaseItem.id,
            price: purchaseItem.unitPrice,
            category: purchaseItem.category,
            quantity: purchaseItem.quantity,
          })),
        },
      },
    },
    {
      plugins: {
        all: false,
        'google-tag-manager': true,
      },
    },
  )

  //Heap
  analytics.track(
    'Studio Checkout - Successful Payment',
    {
      id: purchaseCookie.id,
      revenue: purchaseCookie.raw_total_gross_amount,
      donation_amount: purchaseCookie.raw_total_gross_amount,
      num_attendees: 0, //future carts functionality
      raw_currency_code: purchaseCookie.raw_currency_code,
      paymentSource: purchaseCookie.payment_source,
    },
    {
      plugins: {
        all: false,
        heap: true,
      },
    },
  )

  // Facebook Pixel
  analytics.track(
    'Purchase',
    {
      content_name: 'Donation',
      content_category: pageConfig.isCartEnabled ? undefined : purchaseCookie.items[0].frequency,
      value: purchaseCookie.raw_total_gross_amount,
      currency: purchaseCookie.raw_currency_code,
    },
    {
      plugins: {
        all: false,
        'facebook-pixel': true,
      },
    },
  )

  // Facebook CAPI
  analytics.track(
    'Purchase',
    {
      content_name: 'Donation',
      donation_frequency: pageConfig.isCartEnabled ? undefined : purchaseCookie.items[0].frequency,
      amount: purchaseCookie.raw_total_gross_amount,
      currency: purchaseCookie.raw_currency_code,
    },
    {
      plugins: {
        all: false,
        'facebook-capi': true,
      },
    },
  )
}
